<template>
  <div class="flex flex-col overflow-hidden bg-navy-900 lg:flex-row">
    <div class="relative z-20 flex flex-shrink-0 md:bg-[#23232D] lg:w-[180px]">
      <div
        class="relative flex w-2/3 items-center border-r border-navy-700 pb-3 pt-[10px] md:justify-left lg:w-20 lg:flex-1 lg:flex-col lg:items-center lg:justify-center"
      >
        <iconpark-icon
          name="tianxian"
          size="20"
          class="text-gold-400 ml-4 lg:ml-0"
        ></iconpark-icon>
        <h2
          class="my-1 ml-2 text-center text-[14px] font-bold uppercase leading-none text-gold-400 lg:ml-0"
        >
          实时掉落
        </h2>
        <div class="ml-6 flex flex-col items-center lg:ml-0">
          <div
            class="mb-px flex items-center text-center text-sm font-semibold text-white"
          >
            <div
              class="green-dot-pulse mr-1.5 inline-block h-[6px] w-[6px] flex-shrink-0 rounded-full bg-[#7cfda0]"
            ></div>
            <span class="s-online">{{ userStore?.numberOfOnlineUsers }}</span>
          </div>
          <div
            class="text-center text-10px font-semibold uppercase leading-none text-[#B8BCD0] pt-1"
          >
            在线
          </div>
        </div>
      </div>
      <div
        class="flex w-1/3 self-stretch overflow-hidden md:bg-navy-600 lg:w-[60px] lg:flex-col"
      >
        <button
          :class="liveIndex === 0 ? 'bg-[#23232D] text-gold-400' : ''"
          @click="switchDropMode(0)"
          class="transition-fast flex flex-1 flex-col items-center justify-center md:hover:bg-navy-500"
        >
          <iconpark-icon name="huangguanguanjun" size="22"></iconpark-icon>
          <div
            class="max-w-[40px] pt-1 text-center text-[10px] font-bold uppercase leading-none"
          >
            最佳掉落
          </div>
        </button>
        <div class="h-px bg-navy-700"></div>
        <button
          :class="liveIndex === 1 ? 'bg-[#23232D] text-gold-400' : ''"
          @click="switchDropMode(1)"
          class="transition-fast flex flex-1 flex-col items-center justify-center md:hover:bg-navy-500"
        >
          <iconpark-icon name="baibaoxiang" size="16"></iconpark-icon>
          <div
            class="max-w-[40px] pt-[5px] text-center text-[10px] font-bold uppercase leading-none"
          >
            全部掉落
          </div>
        </button>
      </div>
    </div>
    <div class="relative ml-px grid h-29 w-full overflow-hidden">
      <div
        class="col-start-1 col-end-2 row-start-1 row-end-2 h-full min-h-0"
        style="transform: none"
      >
        <div class="h-full w-screen flex-shrink-0 overflow-hidden">
          <ul
            class="relative flex h-full gap-px will-change-transform"
            style="left: 0px"
          >
            <transition-group name="el-zoom-in-top">
              <li
                class="flex-shrink-0 will-change-transform"
                v-for="(item, index) in theDataOnTheList"
                :key="'history-item-' + index"
                style="transform: translateY(0%) translateZ(0px)"
              >
                <a
                  class="dropCard group relative z-0 block h-full overflow-hidden bg-navy-800 bg-bottom bg-no-repeat"
                  :style="{
                    width: '116px',
                    '--color': `rgba(${item?.itemInfo?.qualityColor}, 1)`,
                  }"
                  ><div
                    class="relative flex h-full transform flex-col items-center p-1.5 transition-transform duration-300 ease-out group-hover:-translate-y-full"
                  >
                    <div
                      class="relative z-0 min-h-0 w-4/5 flex-1 flex items-center justify-center"
                    >
                      <img
                        :src="item?.itemInfo?.cardImage"
                        alt="Skin Image"
                        class="h-16 min-h-0 w-full object-contain"
                      />
                    </div>
                    <div
                      class="w-full truncate pb-3 text-center text-10px font-semibold uppercase leading-none text-[#E1E2EA]"
                    >
                      {{ item?.itemInfo?.cardName }}
                    </div>
                  </div>
                  <div
                    class="flex justify-center h-full w-full transform flex-col items-center p-2 transition-transform duration-300 ease-out group-hover:-translate-y-full"
                  >
                    <div
                      class="h-12 w-12 rounded-lg bg-transparent overflow-hidden mt-2"
                    >
                      <img
                        :src="item?.userInfo?.avatar"
                        alt=""
                        class="w-full h-full object-cover"
                      />
                    </div>
                    <div
                      class="w-full truncate pb-3 text-center text-10px font-semibold uppercase leading-none mt-3"
                    >
                      {{ item?.userInfo?.nickName }}
                    </div>
                  </div>
                  <div
                    class="absolute inset-0 z-[-1] opacity-30 transition-opacity duration-300 group-hover:opacity-30"
                    style="
                      background-image: linear-gradient(
                        transparent 30%,
                        var(--color)
                      );
                    "
                  ></div>
                  <div
                    class="absolute bottom-0 left-0 h-px w-full"
                    style="background-color: var(--color, #999)"
                  ></div
                ></a>
              </li>
            </transition-group>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useUserStore } from "@/store";
import { useSocketStore } from "@/store/socket.store";
import { ref, watch } from "vue";

// 要渲染的数据
const theDataOnTheList = ref<any[]>([]);
// 最佳数据
const bestDropData = ref<any[]>([]);
// 全部数据
const allDropData = ref<any[]>([]);

const socketStore = useSocketStore();

const userStore = useUserStore();

const liveIndex = ref(0);

// 监听全部实时的数据
watch(
  () => [socketStore.allRealtimeDropsData],
  () => {
    // 检查数组长度并删除超过30的元素
    if (allDropData.value.length > 30) {
      allDropData.value.splice(-socketStore.allRealtimeDropsData.length); // 从数组末尾删除3个元素
    }
    socketStore.allRealtimeDropsData.forEach((element) => {
      allDropData.value.unshift(element);
    });
  },
  {
    deep: true,
  }
);

// 监听最佳实时的数据
watch(
  () => [socketStore.bestRealtimeDropsData],
  () => {
    // 检查数组长度并删除超过30的元素
    if (bestDropData.value.length > 30) {
      bestDropData.value.splice(-socketStore.bestRealtimeDropsData.length); // 从数组末尾删除3个元素
    }
    socketStore.bestRealtimeDropsData.forEach((element) => {
      bestDropData.value.unshift(element);
    });
  },
  {
    deep: true,
  }
);

watch(
  () => [socketStore.bestDropData],
  () => {
    bestDropData.value = socketStore.bestDropData;
    if (liveIndex.value === 0) {
      theDataOnTheList.value = socketStore.bestDropData;
    }
  },
  {
    deep: true,
  }
);

watch(
  () => [socketStore.allDropData],
  () => {
    allDropData.value = socketStore.allDropData;
    if (liveIndex.value === 1) {
      theDataOnTheList.value = socketStore.allDropData;
    }
  },
  {
    deep: true,
  }
);

const switchDropMode = (index: number) => {
  liveIndex.value = index;
  if (index === 0) {
    theDataOnTheList.value = bestDropData.value;
  } else {
    theDataOnTheList.value = allDropData.value;
  }
};
</script>

<style lang="scss" scoped>
.green-dot-pulse {
  animation: brightness-animation 1.5s infinite;
}

@keyframes brightness-animation {
  0%,
  100% {
    filter: brightness(1);
  }
  50% {
    filter: brightness(0.3);
  }
}
</style>
